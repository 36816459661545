import PropTypes from 'prop-types';
import React from 'react';
import ModuleWrapperStyles from './ModuleWrapper.style';

const ModuleWrapper = ({ children, className }) => {
  return (
    <>
      <div className={`module-wrapper ${className}`}>{children}</div>
      <style jsx>{ModuleWrapperStyles}</style>
    </>
  );
};

ModuleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModuleWrapper.defaultProps = {
  className: '',
};

export default ModuleWrapper;
