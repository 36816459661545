/* eslint-disable global-require, import/no-dynamic-require */
import React, { useState, useContext } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';

function useLabelsContext({ labels: translations }) {
  const [labels] = useState(translations);
  return { labels };
}

const LabelsContext = createContainer(useLabelsContext);

const withLabels = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithLabels = (props) => {
    const { labels } = useContext(LabelsContext.Context);
    return <WrappedComponent {...props} labels={labels} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithLabels.getInitialProps = getInitialProps;
  }
  ComponentWithLabels.originalName = nameHOC(WrappedComponent);
  ComponentWithLabels.displayName = nameHOC(WrappedComponent, 'WithLabels');
  return ComponentWithLabels;
};

export default LabelsContext;
export { withLabels };
