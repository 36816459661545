import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const ChipStyles = css`
  .module-wrapper {
    clear: both;
    float: left;
    width: 100%;
    background: ${colors.white.shade1};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin-top: 23px;
  }
`;

export default ChipStyles;
