import { debounce, deburr, find, get, isEmpty, isNumber, merge, set } from 'lodash';

const _ = {
  debounce,
  get,
  merge,
  find,
  deburr,
  isNumber,
  set,
  isEmpty,
};

export default _;
