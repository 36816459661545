import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const NoResultsStyles = css`
  .no-result {
    background-color: ${colors.gray.shade6};
    padding: 11px 0 100px 0;
    float: left;
    width: 100%;
    margin-top: -2px;
  }
`;

export default NoResultsStyles;
