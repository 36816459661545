import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const InformationBoxStyles = css`
  .information-box-container {
    padding: 44px;
    h3 {
      font-size: ${typography.xl3};
      font-weight: 300;
      letter-spacing: 0.08px;
      text-align: center;
      line-height: 30px;

      @mixin tabletToDesktop {
        font-size: ${typography.xl6};
        line-height: 42px;
      }
    }
    h3 span {
      color: ${colors.green.shade1};
      word-break: break-all;
    }
    p {
      color: ${colors.gray.shade29};
      font-size: 14px;
      letter-spacing: 0.08px;
      line-height: 19px;
      text-align: center;
      margin-top: 6px;

      @mixin tabletToDesktop {
        font-size: 16px;
      }
    }
  }
  .information-box-container-mkp {
    padding: 51px;

    @mixin mobileToTablet {
      padding: 29px;
    }
    .mkp-block {
      display: inline-block;
      @mixin tabletToDesktop {
        height: 77px;
      }
      @mixin mobileToTablet {
        margin-top: 11px;
      }
      h3 {
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: ${colors.black.shade1};
        letter-spacing: 0.0648px;
        @mixin tabletToDesktop {
          font-size: ${typography.xl6};
          line-height: 42px;
          letter-spacing: 0.0756px;
        }
        @mixin mobileToTablet {
          font-weight: 300;
          font-size: 30px;
          line-height: 36px;
          text-align: center;
          letter-spacing: 0.0648px;
          color: ${colors.black.shade1};
        }
      }
      h3 span {
        color: var(--primary-color-mkp);
        font-weight: 400;
        word-break: break-all;
      }
      p {
        color: ${colors.gray.shade29};
        font-size: 16px;
        letter-spacing: 0.0756px;
        line-height: 19px;
        text-align: center;
        font-weight: normal;
        margin-top: 20px;
        @mixin tabletToDesktop {
          font-size: 16px;
          margin-top: 12px;
        }
        @mixin mobileToTablet {
          width: 276px;
          margin-left: 18px;
          padding: 4px;
        }
      }
    }
  }
`;

export default InformationBoxStyles;
