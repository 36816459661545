import React from 'react';
import PropTypes from 'prop-types';
import ModuleWrapper from '../../../components/ui/ModuleWrapper/ModuleWrapper';
import InformationBoxStyles from './InformationBox.style';

const InformationBox = ({ title, description, term }) => (
  <ModuleWrapper>
    <div className="information-box-container-mkp">
      <div className="mkp-block">
        <h3>
          {title} {term && <span>&apos;{decodeURIComponent(term)}&apos;</span>}
        </h3>
        <p>{description}</p>
      </div>
      <style jsx>{InformationBoxStyles}</style>
    </div>
  </ModuleWrapper>
);

InformationBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
};
InformationBox.defaultProps = {};

export default InformationBox;
