import PropTypes from 'prop-types';
import React from 'react';

const HtmlRendering = ({ html }) => {
  const sanitize = () => {
    return html;
  };
  return (
    <div
      /* eslint-disable react/no-danger */
      dangerouslySetInnerHTML={{ __html: sanitize() }}
      /* eslint-enable react/no-danger */
    />
  );
};

HtmlRendering.propTypes = {
  html: PropTypes.string.isRequired,
};

export default HtmlRendering;
