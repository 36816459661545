import { icons } from './styles';

const constants = {
  lang: 'lang',
  searchTerm: 'Ntt',
  DEFAULT_LAYOUT: 'LIST',
  SESSION_STORAGE_SCROLL: 'scroll',
  SESSION_STORAGE_NEW_PAGE: 'newPage',
  ZONE: {
    COOKIE_NAME: 'zoneData',
    DEFAULT_ZONE_ID: '13',
    DEFAULT_ZONE_NAME: 'R. Metropolitana',
  },
  AD_TYPES_FOR_VAS: {
    BRANDS: 'brands',
    PRODUCTS: 'products',
  },
  STORE_NAMES_FOR_VAS: {
    SO_COM: 'so_com',
    TO_COM: 'to_com',
    SODIMAC: 'sodimac',
    TOTTUS: 'tottus',
    LINIO: 'linio',
    TIENDA: 'tienda',
    HOMECENTER: 'homecenter',
  },
  X_CHANNEL_IDS_HEADERS_VALUES: {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
  },
  TENANT_TYPES: {
    FALABELLA_CL: 'falabella-cl',
    FALABELLA_CO: 'falabella-co',
    FALABELLA_PE: 'falabella-pe',
    SODIMAC_CL: 'sodimac-cl',
    SODIMAC_PE: 'sodimac-pe',
    SODIMAC_CO: 'sodimac-co',
  },
  COUNTRIES_ABBREVIATIONS: {
    CHILE: 'cl',
    PERU: 'pe',
    COLOMBIA: 'co',
  },
  HEADER_RESPONSE_MY_ACCOUNT_LABEL: 'actionAccount',
  SESSION_COOKIE_NAME: 'JSESSIONID',
  SEARCH_REDIRECT_STATUS: 'alt',
  REDIRECT_CODE: 307, // IE11 was not able to recognize 302
  AMANDA_CLOSE_MESSAGE: 'close',
  CONVERSATION_ID_KEY: 'amandaConversationId',
  FACETS_STICKY_HEADER_THRESHOLD_SLP: 190,
  FACETS_STICKY_HEADER_THRESHOLD_PLP: 297,
  HIDE_TAXONOMY_WHEN_SCROLL_THRESHOLD: 580,
  FACETS: {
    PRICE_SEARCH_KEY: 'r.derived.price.search',
    PRICE_SEARCH_KEY_TOTTUS: 'r.derived.unitPrice',
    PRICE_RANGE_KEY: 'f.range.derived.price.search',
    PRICE_GROUP_NAME: 'Precio',
    PRICE_GROUP_EVENT_NAME: 'Precio_event',
    GROUP_DESPACHO: 'Despacho',
    GROUP_RT_ZONES: 'PickupFromStore',
    SHIPPING: 'Shipping',
    CATEGORY_PATH_SEARCH: 'f.product.parentCategoryPaths.pathSearch',
    VALUE_TEXT_CHARCOUNT: 50,
    FACET_MOBILE_CONTAINER_HEIGHT: 50,
  },
  DEFAULT_SESSION: '0g9XUm58M2WOdmeE9rVBv1AL.node66',
  SITE_VERIFICATION: {
    cl: '',
    co: '2GbdOhrt7MTcbI3a440h0FeTmQyUIgTQjNZqNm-G-Eg',
    ar: '',
    pe: '-UW2qaAfDAZiQuF-kQXYZcTkWXYVMGDOAW8WJXPsEbE',
  },
  META: {
    DESCRIPTION: {
      cl: {
        PDP: 'Compra $product_name$ de $brand_name$ en Falabella $country$. Descubre sus características principales y adquiere la mejor opción para ti.',
        PLANS:
          'Llévate el celular $brand_name$ $product_name$ que más te guste con el plan perfecto para ti. Encuéntralo en Falabella $country$.',
      },
      pe: {
        PDP: 'Compra online $product_name$ de $brand_name$ en falabella.com conoce sus características y adquiere la mejor opción para ti.',
        PLANS: 'Elige el celular $brand_name$ $product_name$ con increíbles descuentos. Encuétralo en falabella.com',
      },
      co: {
        PDP: 'Compra $product_name$ de $brand_name$ en Falabella $country$. Descubre sus características principales y adquiere la mejor opción para ti.',
        PLANS:
          'Llévate el celular $brand_name$ $product_name$ que más te guste con el plan perfecto para ti. Encuéntralo en Falabella $country$.',
      },
      ar: {
        PDP: 'Compra $product_name$ de $brand_name$ en Falabella $country$. Descubre sus características principales y adquiere la mejor opción para ti.',
        PLANS:
          'Llévate el celular $brand_name$ $product_name$ que más te guste con el plan perfecto para ti. Encuéntralo en Falabella $country$.',
      },
    },
  },
  COUNTRY: {
    cl: 'Chile',
    pe: 'Perú',
    co: 'Colombia',
    ar: 'Argentina',
  },
  PAGE_TITLE: {
    cl: 'falabella.com - Mejor Compra Online',
    co: 'falabella.com - Bienvenidos a Nuestra Tienda Online',
    ar: 'Falabella Argentina',
    pe: 'falabella.com - Bienvenidos a Nuestra Tienda Online',
  },

  SKIP_LAZY_LOAD_IMAGE_COUNT_MOBILE: 4,
  SKIP_LAZY_LOAD_IMAGE_COUNT_DESKTOP: 8,
  TRACING_HEADERS: [
    'x-request-id',
    'x-b3-traceid',
    'x-b3-spanid',
    'x-b3-parentspanid',
    'x-b3-sampled',
    'x-b3-flags',
    'x-ot-span-context',
  ],
  ERROR_MESSAGE_TEXT: 'Product Search Failed',
  PAGINATION_TOTAL_RESULTS_LIMIT: 9600,
  MIN_SEARCH_TERM_LENGTH: 2,
  FACEBOOK_LOGIN_APP_ID: {
    cl: 1488875737994057,
    co: 245017215690503,
    ar: 692333450829514,
    pe: 528661077245574,
  },
  DEFAULT_LAYOUT_TYPE: 'HARDLINE',
  PRODUCT_CONSTANTS: {
    HARDLINE: 'HARDLINE',
    SOFTLINE: 'SOFTLINE',
    CONNECT: 'CONNECT',
  },
  HTTP_RES_CODE: {
    FOUND: 302,
    TEMP_REDIRECT: 307,
    PERM_REDIRECT: 301,
    NOT_FOUND_CODE: 404,
  },
  DEVICE_DESKTOP: 'desktop',
  DEVICE_MOBILE: 'mobile',
  DEVICE_TABLET: 'tablet',
  PLATFORM_TYPE: {
    ANDROID: 'android',
    IOS: 'ios',
  },
  LAYOUT_2_GRID: '2_GRID',
  LAYOUT_3_GRID: '3_GRID',
  LAYOUT_4_GRID: '4_GRID',
  LAYOUT_1_GRID: '1_GRID',
  LAYOUT_LIST: 'LIST',
  BODY_COPY_STYLES: {
    copy5: 'copy5',
    copy2: 'copy2',
    title1: 'title1',
    title5: 'title5',
  },
  SPANISH_DAYS_OF_WEEK: {
    1: 'Lun',
    2: 'Mar',
    3: 'Mié',
    4: 'Jue',
    5: 'Vie',
    6: 'Sáb',
    0: 'Dom',
  },
  SPANISH_MONTHS_MAPPER: {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
  },
  SPANISH_MONTHS_MAPPING: {
    LUN: 'Lun',
    MAR: 'Mar',
    MIE: 'Mié',
    JUE: 'Jue',
    VIE: 'Vie',
    SAB: 'Sáb',
    DOM: 'Dom',
  },
  AVAILABILITY_OUTOFSTOCK: 'OutOfStock',
  AVAILABILITY_INSTOCK: 'InStock',
  AVAILABILITY_HOMEDELIVERY: 'HomeDelivery',
  AVAILABILITY_PICKUPINSTORE: 'PickupInStore',
  AVAILABILITY_SITETOSTORE: 'SiteToStore',
  AVAILABILITY_PURCHASEONLINE: 'PurchaseOnline',
  AVAILABILITY_NETWORKSTOCK: 'Network',
  PRODUCT_TYPE: {
    SOFT_GOOD: 'SOFT_GOOD',
    GIFTCARD: 'GIFTCARD',
  },
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  NOT_FOUND: 'NOT_FOUND',
  DEFAULT_CMR_QUANTITY: 1,
  DEFAULT_CMR_INSTALLMENTS: 10,
  DEFAULT_CMR_BANK_NAME: 'unselected',
  DEFAULT_CMR_BANK_NAME_AR: 'CMR',
  PAGES: {
    PDP: 'PDP',
    PLP: 'PLP',
    SLP: 'SLP',
    PLANS: 'PLANS',
    CART: 'CART',
  },
  YOUTUBE_THUMBNAIL_URL: 'https://img.youtube.com/vi/{id}/hqdefault.jpg',
  MARKETPLACE_URL_KEY: 'MARKETPLACE_URL',
  VIEW_TEMPLATE: {
    TYPE_A: 'TYPE_A',
    TYPE_C: 'TYPE_C',
  },
  DIGITAL_DATA_STORAGE_NAME: 'analyticsInfo',
  PRODUCT_SORT_DESCRIPTION: 'La modelo mide 175 cm y lleva una talla S. Su pecho mide 84 cm y su cintura, 60 cm.',
  WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN: 5,
  CURRENCY: {
    CL: 'CLP',
    CO: 'COP',
    AR: 'ARS',
    PE: 'PEN',
  },
  MAX_CART_PRODUCT: 999,
  CONNECT_SPECIFICATIONS: [
    { specId: 'camera', value: 'Cámara principal', text: 'Cámara Trasera' },
    { specId: 'camera', value: 'Cámara frontal', text: 'Cámara Frontal' },
    { specId: 'memory', value: 'Memoria interna', text: 'Memoria Interna' },
    { specId: 'battery', value: 'Capacidad batería (mAh)', text: 'Batería' },
    { specId: 'core', value: 'Velocidad del procesador', text: 'Procesador' },
  ],
  CONNECT_SPECIFICATIONS_PRIORITY: [
    {
      specId: 'camera',
      text: ['Cámara posterior', 'Cámara frontal'],
      label: ['Cámara Trasera', 'Cámara Frontal'],
    },
    { specId: 'memory', text: 'Memoria interna', label: 'Memoria Interna' },
    { specId: 'battery', text: 'Capacidad batería (mAh)', label: 'Batería' },
    { specId: 'core', text: 'Velocidad del procesador', label: 'Procesador' },
    {
      specId: 'screen-size',
      text: 'Tamaño de la pantalla',
      label: 'Tamaño de la pantalla',
    },
    { specId: 'os', text: 'Sistema operativo', label: 'Sistema operativo' },
    {
      specId: 'water-proof',
      text: 'Resistente al agua',
      label: 'Resistencia al agua',
    },
    {
      specId: 'core',
      text: 'Núcleos del procesador',
      label: 'Núcleos del procesador',
    },
    {
      specId: 'memory',
      text: 'Memoria externa incluida',
      label: 'Memoria externa incluida',
    },
    { specId: 'camera', text: 'Flash frontal', label: 'Flash frontal' },
    { specId: 'screen', text: 'Tipo de pantalla', label: 'Tipo de pantalla' },
    {
      specId: 'core',
      text: 'Marca y modelo del procesador',
      label: 'Marca y modelo del procesador',
    },
    { specId: 'memory', text: 'Memoria RAM', label: 'Memoria RAM' },
    {
      specId: 'memory',
      text: 'Memoria expandible',
      label: 'Memoria expandible',
    },
    { specId: 'sim', text: 'Dual SIM', label: 'Dual SIM' },
    { specId: 'gps', text: 'GPS integrado', label: 'GPS integrado' },
    { specId: 'connectivity', text: 'Conectividad', label: 'Conectividad' },
    { specId: 'finger', text: 'Lector de huella', label: 'Lector de huella' },
    { specId: 'made-in', text: 'Hecho en', label: 'Hecho en' },
    { specId: 'weight', text: 'Peso', label: 'Peso' },
  ],
  SESSION_CNF_KEY_NAME: 'FA_SESSION_CNF',
  SESSION_CNF_TTL: 10000,
  ADD_TO_CART_WARNING_MSG_LIMIT: 3,
  MODEL_CHARACTERISTICS_SPECIFICATION_ID: 'Medidas de la modelo',
  FALABELLA_MARKET_PLACE_ID: 'FALABELLA',
  FALABELLA_REGEX: /falabella/i,
  SODIMAC_REGEX: /sodimac/i,
  HOMECENTER_REGEX: /homecenter/i,
  SO_COM_REGEX: /so_com/i,
  TO_COM_REGEX: /to_com/i,
  TOTUS_REGEX: /tottus/i,
  LINIO_REGEX: /linio/i,
  LINIO_TIENDA_REGEX: /(linio|tienda)/,
  FACETS_WITH_SWITCH_REGEX: /(Envío gratis)/i,
  FIRST_PARTY_SELLER_REGEX: /(tottus|sodimac|linio|falabella|homecenter|so_com|to_com)/,
  SHIPPING_GROUP_REGEX: /Shipping/,
  EVENT_REGEX: /MULTI_SELECT_EVENT/,
  FREE_SHIPPING_REGEX: /(Envío gratis)/i,
  SAME_DAY_DELIVERY_REGEX: /(Llega hoy)/i,
  NEXT_DAY_DELIVERY_REGEX: /(Llega mañana)/,
  CATEGORY_REGEX: /Categoría/i,
  SAME_DAY_PICKUP_IN_STORE: /(Retira hoy)/,
  NEXT_DAY_PICKUP_IN_STORE: /(Retira mañana)/,
  SHIPPING_SWITCH_FACET_TYPES: /(f.shipping.hd_same_day_zones|f.shipping.hd_next_day_zones)/,
  SHIPPING_FACET_NAMES: ['Llega hoy', 'Llega mañana', 'Retira hoy', 'Retira mañana'],
  SAME_DAY_HD_CC_REGEX: /(Llega hoy|Retira hoy|Hoy)/,
  SHIPPING_HD_REGEX: /(Llega hoy|Llega mañana)/,
  SHIPPING_CC_REGEX: /(Retira hoy|Retira mañana)/,
  STORE_PLACEHOLDER: '{{tienda}}',
  MARKET_PLACE: 'Marketplace',
  STORES: {
    sodimac: 'sodimac',
    tottus: 'tottus',
    linio: 'linio',
    tienda: 'tienda',
    homecenter: 'homecenter',
  },
  DECIMAL_SEPARATOR: {
    cl: ',',
    co: ',',
    ar: ',',
    pe: '.',
  },
  DOT_SEPARATOR: {
    cl: '.',
    co: '.',
    ar: '.',
    pe: ',',
  },
  SEO_FRIENDLY_FACETS: [
    'f.product.attribute.Tipo',
    'f.product.brandName',
    'f.variant.custom.Tamaño_de_la_pantalla',
    'f.product.attribute.Capacidad_de_carga',
    'f.product.attribute.Tamaño_del_respaldo',
    'f.product.attribute.Material_del_tapiz',
    'f.product.attribute.Material_de_la_estructura',
    'f.product.attribute.Resolución',
    'f.product.attribute.Procesador',
    'f.product.attribute.Número_de_puestos',
    'f.product.attribute.Aro',
    'f.product.attribute.Número_de_hilos',
    'f.product.attribute.Número_de_puertas',
    'f.product.attribute.Número_de_quemadores',
    'f.product.attribute.Memoria_RAM',
    'f.product.attribute.Tipo_de_piel',
    'f.product.attribute.Tamaño_de_la_pantalla',
    'f.product.attribute.Capacidad_de_lavado',
    'f.derived.product.Tamano_de_la_pantalla',
    'f.product.attribute.Cantidad_de_puertas',
    'f.derived.product.Memoria_RAM',
  ],
  DY_ID: {
    staging: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
    },
    beta: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
      //  Since there is no property in FACO and FAPE beta env we will point to production.
    },
    www: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
    },
  },
  GTM_BASE: 'https://www.googletagmanager.com',
  GTM_JQUERY: 'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js',
  MEDALLIA: 'https://resources.digital-cloud.medallia.com/wdcus/55961/onsite/embed.js',
  FIT_ANALYTICS: 'https://integrations.fitanalytics.com/shop/falabella/pdp.js',
  GOOGLE_MAP: 'https://maps.googleapis.com/maps/api/js?',
  PHOTOSLURP: 'https://static.photoslurp.com/widget/v3/loader.js',
  DIGITAL_DATA_MANAGER_PDP_SCRIPT: {
    dev: 'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blte2189fa43abf355b/pdp.cjs',
    staging: 'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt9dc4ff987f3c1540/pdp.cjs',
    beta: 'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3fa4d6f2031ea63e/pdp.cjs',
    www: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt19c4fcb2d0d5ff0a/pdp.cjs',
  },
  DIGITAL_DATA_MANAGER_XLP_SCRIPT: {
    dev: 'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blt0e1117a5dae9f7bc/xlp.cjs',
    staging: 'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf3022ea071e9897c/xlp.cjs',
    beta: 'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt4870c50989ab1d66/xlp.cjs',
    www: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt006a6122094a0f5a/xlp.cjs',
  },
  DIGITAL_DATA_MANAGER_NO_RESULT_SCRIPT: {
    dev: 'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blt5bfd70100db5c053/noResult.cjs',
    staging: 'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blta2518e2baacf2b83/noResult.cjs',
    beta: 'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltb3b1a8fe31edced4/noResult.cjs',
    www: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltc1b0c6ffbc670416/noResult.cjs',
  },
  VWO_SCRIPTS: {
    cl: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ae1beb67525ed26/vwo.js',
    pe: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf1762da3c97b64e4/vwo.js',
    co: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf0b1376d26dd28cc/vwo.js',
  },
  SUGGESTION_MAX_WORD_COUNT: 30,
  guidedSellingColors: {
    amarillo: '#eec12b',
    azul: '#0736c4',
    blanco: '#ffffff',
    burdeo: '#440000',
    cafe: '#8e5b3e',
    gris: '#c6b8b8',
    marron: '#8e5b3e',
    morado: '#77337f',
    musgo: '#1c9e43',
    naranja: '#ef992f',
    naranjo: '#ef992f',
    negro: '#000000',
    plomo: '#c6b8b8',
    rojo: '#ce0c0c',
    rosa: '#f276d5',
    rosado: '#f276d5',
    salmon: '#ef992f',
    verde: '#1c9e43',
    violeta: '#d225db',
    sincolor: 'Sin color',
  },
  COMMERCE_API_ALERTS: {
    AL013: {
      message: 'No se pudo agregar el ID de producto',
    },
    CART_CONFIG_BREACH: {
      message: 'Se superó la cantidad máxima permitida por producto',
    },
    AL003: {
      message: 'Precio no encontrado',
    },
    AL004: {
      message: 'El inventario está agotado',
    },
    AL005: {
      message: 'Inventario no encontrado',
    },
    CHECKOUT_SLOT_NOT_AVAILABLE: {
      message: 'Lo sentimos. Intenta con otra comuna o revisa la disponibilidad del envío a domicilio.',
    },
  },
  COMMERCE_API_ERRORS: {
    CART_CONFIG_BREACH: {
      message: 'Se superó la cantidad máxima permitida por producto',
    },
    CHECKOUT_SLOT_NOT_AVAILABLE: {
      message: 'Lo sentimos. Intenta con otra comuna o revisa la disponibilidad del envío a domicilio.',
    },
    AL001: {
      message: 'El vendedor subió el precio de este producto.',
    },
    AL002: {
      message: '¡Buena noticia! El vendedor bajó el precio de este producto.',
    },
    AL003: {
      message: 'Tuvimos un problema, no pudimos cargar el precio.',
    },
    AL004: {
      message: 'Lo sentimos, este producto recién se agotó.',
    },
    AL005: {
      message: 'Tuvimos un problema, no encontramos las unidades disponibles.',
    },
    AL006: {
      message: 'Lo sentimos, el vendedor despublicó este producto.',
    },
    AL007: {
      message: 'Se aplicó la promoción.',
    },
    AL008: {
      message: 'Se eliminó la promoción.',
    },
    AL009: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL010: {
      message: 'El cupón no es válido.',
    },
    AL011: {
      message: 'Agregamos el cupón con éxito.',
    },
    AL012: {
      message: 'Eliminamos el cupón.',
    },
    AL013: {
      message: 'El cupón expiró.',
    },
    AL014: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL015: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL016: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL017: {
      message: 'Superaste la cantidad máxima de productos que puedes llevar en tu Carro.',
    },
    AL018: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL019: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL020: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL021: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL022: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL023: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL024: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL025: {
      message: 'Lo sentimos, este producto recién se agotó.',
    },
  },
  COMMERCE_LIMIT_API_ALERTS: {
    AL023: {
      message: 'Has alcanzado la cantidad máxima para este producto.',
    },
    AL021: {
      message: 'Has alcanzado la cantidad máxima de productos para tu Bolsa de compras',
    },
  },
  COMMERCE_LIMIT_API_ERRORS: {
    AL023: {
      message: 'Has alcanzado la cantidad máxima para este producto.',
    },
    AL021: {
      message: 'Has alcanzado la cantidad máxima de productos para tu Bolsa de compras',
    },
  },
  LS_HOME_DELIVERY_KEY: 'AVAILABILTY_HD',
  LS_STORE_PICKUP_KEY: 'AVAILABILTY_CC',
  COOKIE_HOME_DELIVERY_KEY: 'availabilityHD',
  COOKIE_STORE_PICKUP_KEY: 'availabilityCC',
  DEFAULT_POLITICAL_ID: 'AFZone',
  DEFAULT_PRICE_GROUP_ID: '96',
  DEFAULT_ZONES: 'AFZone',
  ANDES_DEF_ZONE: {
    politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
    priceGroupId: '96',
    zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
    oldZones: ['LOSC', '130617', '13'],
    name: 'Seleccion ubicacion',
  },
  DEF_ZONE: {
    cl: {
      staging: {
        politicalId: '4856187d-c035-45ac-9f4e-9290b9e9f85f',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
    },
    pe: {
      staging: {
        politicalId: 'c6b7a50c-60f3-4687-91d0-9461d538812a',
        priceGroupId: '2',
        zones: ['150000'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion',
      },
    },
    co: {
      staging: {
        politicalId: '59a6aec4-fc51-4f3b-ab5e-62fd7a1076c0', // TBD
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
    },
  },
  MERCHANT_CATEGORY_PLAN_ID: ['J11050102'],
  PLAN_HELP_NUMBER: '600 390 4100',
  STORE_MODAL_REGION_DATA: 'storeModalRegion',
  STORE_AVAILABILITY_MODAL_WIDTH: {
    screen1: 720,
    screen2: 500,
    screen3: 800,
  },
  STORE_AVAILABILITY_MODAL_HEIGHT: {
    screen1: 339,
    screen2: 531,
    screen3: 575,
  },
  STOCK_CATEGORY_CONFIG: {
    EXACT_UNITS: 'EXACT_UNITS',
    RANGE: 'RANGE',
  },
  ATC_SESSION_EXPIRED_CODE: 'CHECKOUT_INVALID_SESSION',
  DEMOUP_EXPERIENCE_ENABLED: 'EXP_MODEUP',
  DEMOUP_VIDEO_STATIC: 'DEMOUP_VIDEO_STATIC',
  CMR_LIST_OPTIONS: {
    cl: [
      {
        title: 'Con tarjeta CMR',
        divideFactor: 200,
        logo: icons.cmrTarjetaIcon,
      },
      {
        title: 'Con Débito Banco Falabella',
        divideFactor: 400,
        logo: icons.bancoIcon,
      },
      {
        title: 'Con Cuenta Fpay',
        divideFactor: 300,
        logo: icons.fpayIcon,
      },
      {
        title: 'Con tarjeta de crédito',
        divideFactor: 600,
        logo: icons.creditoIcon,
      },
      {
        title: 'Con tarjeta de débito',
        divideFactor: 600,
        logo: icons.debitoIcon,
      },
    ],
    co: [
      {
        title: 'Con tarjeta CMR',
        divideFactor: 1000,
        logo: icons.cardIconsFaco.cmr,
      },
      {
        title: 'Con Débito Banco Falabella',
        divideFactor: 2000,
        logo: icons.cardIconsFaco.banco,
      },
      {
        title: 'Con tarjeta de crédito',
        divideFactor: 4000,
        logo: icons.creditoIcon,
      },
      {
        title: 'Con tarjeta de débito',
        divideFactor: 4000,
        logo: icons.debitoIcon,
      },
    ],
  },
  RELATED_KEYWORDS_HEADING: 'Otras personas también buscaron:',
  SPONSORED_PRODUCTS_CAROUSEL_HEADING: 'Las marcas recomiendan',
  SPONSORED_PRODUCTS_CAROUSEL_SUB_HEADING: 'Patrocinado',
  FREE_SHIPPING: 'Envío gratis',
  MULTI_SELECT_EVENT: 'MULTI_SELECT_EVENT',
  CATEGORY: 'Categoría',
  ICON_CLASSES_MAPPING: {
    'f.range.product.averageOverallRating': 'csicon-star_full_filled star',
  },
  BRAND_FACET: /f.product.brandName/i,
  FACET_ATTRIBUTE_NAME_MAPPINGS: {
    brandName: 'Brand',
  },
  IS_USER_OVER_18: 'isUserOver18',
  SKIP_ALERT_CODES: 'AL004,AL005,AL022,AL024',
};

export default constants;
