import { getCookieDomain } from '@digital-retail/store-manager';
import { isWindow } from './deviceChecker';
import Logger from './Logger';

const CookieSchema = {
  zoneData: {
    type: 'object',
    encoded: true,
  },
  locationData: {
    type: 'object',
    encoded: true,
  },
  customerData: {
    type: 'object',
    encoded: true,
  },
  DYN_USER_ID: {
    type: 'string',
    encoded: false,
  },
  DYNAMIC_DATA_COOKIE: {
    type: 'string',
    encoded: false,
  },
  VENDOR_DATA: {
    type: 'string',
    encoded: false,
  },
  AGENT_DATA: {
    type: 'string',
    encoded: false,
  },
  CATALYST_DATA_COOKIE: {
    type: 'string',
    encoded: false,
  },
  priceGroupId: {
    type: 'string',
    encoded: false,
  },
  zones: {
    type: 'string',
    encoded: false,
  },
  politicalId: {
    type: 'string',
    encoded: false,
  },
  seller_experience: {
    type: 'string',
    encoded: false,
  },
  availabilityHD: {
    type: 'object',
    encoded: true,
  },
  availabilityCC: {
    type: 'object',
    encoded: true,
  },
  PDP_WIDGETS: {
    type: 'string',
    encoded: false,
  },
  SELECTED_HD: {
    type: 'object',
    encoded: true,
  },
  SELECTED_CC: {
    type: 'object',
    encoded: true,
  },
  SELECTED_PID_CC: {
    type: 'string',
    encoded: false,
  },
};

const getCookie = (cookieName) => {
  const result = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  return result ? result.pop() : '';
};

const getSSRCookie = (cookies, cookieName) => {
  const result = cookies.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  return result ? result.pop() : '';
};

const encode = (raw) => {
  return isWindow() ? btoa(unescape(encodeURIComponent(raw))) : Buffer.from(raw).toString('base64');
};

const decode = (raw) => {
  return isWindow()
    ? decodeURIComponent(escape(window.atob(raw.replace(/"/g, ''))))
    : Buffer.from(raw, 'base64').toString();
};

const setCookie = ({
  name,
  value,
  expiryDays = 1000,
  domain,
  path = '/',
  addQuotes = false,
  regionCode = 'cl',
  store,
}) => {
  let cookie = value;
  let expiration = '';
  const domainStr = `;domain=${domain || getCookieDomain({ regionCode, store })}`;
  const pathStr = `;path=${path}`;
  const schema = CookieSchema[name];

  if (schema) {
    if (schema.type === 'object') {
      cookie = JSON.stringify(cookie);
    }

    cookie = schema.encoded ? encode(cookie) : cookie;
  }

  if (expiryDays) {
    const date = new Date();
    const DAY = 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + expiryDays * DAY);
    expiration = `; expires=${date.toGMTString()}`;
  }

  let cookieStr = `${name}=${cookie}${domainStr}${pathStr}${expiration}; Priority=High`;
  if (addQuotes) {
    cookieStr = `"${cookieStr}"`;
  }

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    document.cookie = cookieStr;
    return cookieStr;
  }
  return false;
};

const setHostOnlyCookie = ({ name, value, addQuotes = false, expiryDays = 1000, path = '/' }) => {
  let cookie = value;
  let expiration = '';
  const pathStr = `;path=${path}`;
  const schema = CookieSchema[name];
  if (schema) {
    if (schema.type === 'object') {
      cookie = JSON.stringify(cookie);
    }

    cookie = schema.encoded ? encode(cookie) : cookie;
  }

  if (expiryDays) {
    const date = new Date();
    const DAY = 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + expiryDays * DAY);
    expiration = `; expires=${date.toGMTString()}`;
  }

  let cookieStr = `${name}=${cookie}${pathStr}${expiration}; Priority=High`;

  if (addQuotes) {
    cookieStr = `"${cookieStr}"`;
  }

  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    document.cookie = cookieStr;
    return cookieStr;
  }
  return false;
};

const removeCookie = ({ name, domain, path }) => {
  const domainStr = domain ? `; domain=${domain}` : '';
  const pathStr = path ? `; path=${path}` : '';

  if (isWindow()) {
    document.cookie = `${name}=${domainStr}${pathStr}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

const parseCookieValue = (name, value) => {
  if (!value) return undefined;

  const { encoded, type } = CookieSchema[name] || {};

  const decodedValue = encoded ? decode(value) : value;
  if (type === 'object') {
    try {
      return JSON.parse(decodedValue);
    } catch (e) {
      Logger.error(`Error parsing cookie ${name} => ${value}`);
      return undefined;
    }
  }

  /** Uncomment below lines, if CookieSchema.type has number  */
  // else if (schema.type === 'number') {
  //   return parseInt(value, 10);
  // }

  return decodedValue;
};

const parseCookies = (str) => {
  if (!str || str === '') {
    return {};
  }

  const cookies = {};
  str.split(';').forEach((cookie) => {
    const c = cookie.split('=');
    const name = c[0].trim();
    const value = c[1];
    cookies[name] = parseCookieValue(name, value);
  });
  return cookies;
};

const getUserDataCookieName = () => {
  return 'CATALYST_DATA_COOKIE';
};

// Remove cookie cookie pairs key1::val1||key2::val2||key3::val3
// Provided cookie[keyX]=valX
// If value is not provided, cookie[key] will be removed
const removeCookiePairs = (pairStr, regionCode, store) => {
  const pairs = pairStr.split('||');

  pairs.forEach((pair) => {
    const [key, value] = pair.split('::');

    if (key && (!value || (value && getCookie(key) === value))) {
      removeCookie({ name: key, path: '/' });
      removeCookie({
        name: key,
        domain: getCookieDomain({ regionCode, store }),
        path: '/',
      });
    }
  });
};

const CookieStorage = {
  setCookie,
  getCookie,
  parseCookies,
  CookieSchema,
  removeCookie,
  parseCookieValue,
  getUserDataCookieName,
  removeCookiePairs,
  setHostOnlyCookie,
  getSSRCookie,
};

export default CookieStorage;
