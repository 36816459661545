import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import HtmlRendering from '../../../components/ui/HtmlRendering/HtmlRendering';
import ModuleWrapper from '../../../components/ui/ModuleWrapper/ModuleWrapper';
import httpService from '../../../utils/httpService';

const DynamicBanner = ({ url }) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const { data, statusCode } = await httpService().get(url, {
        readBody: (res) => res.text(),
        includeTracing: false,
      });
      setHtml(statusCode === 200 ? data : null);
    };
    fetchData();
  }, []);
  if (!html) return null;
  return (
    <ModuleWrapper>
      <HtmlRendering html={html} />
    </ModuleWrapper>
  );
};

DynamicBanner.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DynamicBanner;
